<template>
  <div class="investor-cert">
    <div class="title font-md">
      <b>{{ state.investors[state.form.code].title }}</b>
      <span> 인증 신청</span>
    </div>
    <div class="desc font-sm">항목을 입력하신 후 인증 신청 버튼을 눌러주세요.</div>
    <div class="content">
      <div class="part">
        <div class="subject font-sm">1. {{ state.investors[state.form.code].title }} 세부 유형</div>
        <div class="form">
          <div class="form-check form-check-inline font-sm" v-for="(t, key, idx) in state.investors[state.form.code].types" :key="idx">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="'type' + key" :value="key" v-model="state.investors[state.form.code].type">
            <label class="form-check-label" :for="'type' + key">{{ t.title }}</label>
          </div>
        </div>
      </div>
      <div class="part">
        <div class="subject font-sm">2. {{ state.investors[state.form.code].types[state.investors[state.form.code].type].title }} 제출서류 안내</div>
        <div class="form">
          <ul class="tight font-sm">
            <li v-for="(g, idx1) in state.investors[state.form.code].types[state.investors[state.form.code].type].guides" :key="idx1">
              <div class="target">{{ g.target }}</div>
              <div class="document">{{ g.document }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="part">
        <div class="subject font-sm">3. 증빙 서류</div>
        <div class="form">
          <div class="file">
            <input type="file" :accept="$definitions.limits.fileExtensions.allStr" class="font-sm" ref="fileRef" @change="setFile($event)"/>
          </div>
        </div>
      </div>
      <div class="part projects" v-if="state.form.code === '04'">
        <div class="subject font-sm">4. 관련 투자 정보</div>
        <ul class="tight font-sm">
          <li :class="{ active: state.project.investSeq === p.investSeq }" v-for="(p, idx) in state.project.list" :key="idx" @click="state.project.investSeq = p.investSeq">
            <div>
              <b>회사명: </b>
              <span>{{ p.corporateName }}</span>
            </div>
            <div class="pt-2 pb-2">
              <b>펀딩명: </b>
              <span>{{ p.projectName }}</span>
            </div>
            <div>
              <b>펀딩 기간(투자 가능 기간): </b>
              <span>{{ p.fundingStartDate }} ~ {{ p.fundingEndDate }}</span>
            </div>
          </li>
        </ul>
        <div class="more" v-if="state.project.list.length < state.project.count">
          <button class="font-sm btn btn-light" @click="loadInvestProjects()" :disabled="!state.project.loaded">더 보기</button>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="btn prev btn btn-light" @click="$store.commit('closeModal', { name: component.name })">다음에</button>
      <button class="next btn btn-point" @click="submit()">인증 신청하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalInvestorCert";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});
      state.form.code = modalParams.code;
      state.form.code === "04" && loadInvestProjects(true);
    });

    const state = reactive({
      project: {
        investSeq: "",
        loaded: false,
        args: {
          pageIndex: 1,
          pageSize: 5,
          pageUnit: 3,
          mode: "select"
        },
        count: 0,
        list: [],
      },
      form: {
        code: "",
        detailCode: "",
      },
      investors: {
        "02": {
          title: "적격투자자",
          type: "0201",
          types: {
            "0201": {
              title: "소득적격투자자",
              guides: [{
                target: "(1) 금융소득종합과세 대상자",
                document: "[제출서류] 직전 과세기간의 종합소득세 신고서"
              }, {
                target: "(2) 사업소득과 근로소득의 합계액이 1억원 이상인 개인",
                document: "[제출서류] 직전 과세기간의 종합소득세 신고서 및 근로소득원천징수영수증"
              }, {
                target: "(3) 최근 2년간 5회 이상 증권형 크라우드펀딩 모집에 투자한 사람으로서 누적투자금액이 1,500만원* 이상인 자\n*단, 2019.12.31.일까지는 1,500만원이 아닌 1,000만원으로 함",
                document: "[제출서류] 온라인소액투자증권 투자내역 확인서"
              }, {
                target: "(4) 최근사업연도말 현재 자기자본이 10억원을 초과하는 법인",
                document: "[제출서류] 최근 사업연도말 법인세 세무조정 계산서 또는 세무대리인의 재무제표증명원"
              }]
            },
            "0202": {
              title: "금융투자전문인력",
              guides: [{
                target: "",
                document: "[제출서류] 해당 금융자격증 및 등록확인 서류\n(3년 이상의 금융투자회사 재직증명서 혹은 금융투자협회에서 발급한 전문인력 등록확인서)"
              }]
            }
          }
        },
        "03": {
          title: "전문투자자",
          type: "0301",
          types: {
            "0301": {
              title: "전문투자자",
              guides: [{
                target: "(1) 당연전문투자자",
                document: "[제출서류] 사업자등록증 및 법인등기부등본"
              }, {
                target: "(2) 지정전문투자자\n국내법인 · 단체(금융투자상품잔고 100억원이상 등) 및 이에 준하는 외국법인·단체",
                document: "[제출서류] 금융투자협회 전문투자자확인증"
              }, {
                target: "내국인(금융투자상품잔고 50억원이상 등) 및 이에 준하는 외국인 개인",
                document: "[제출서류] 신분증 사본 및 해당 투자상품 잔고증명서(직전 1년 이내)"
              }]
            },
            "0302": {
              title: "전문가",
              guides: [{
                target: "회계법인, 신용평가회사, 중소기업창업투자회사",
                document: "[제출서류] 법인등기부등본(말소사항 포함)"
              }]
            },
            "0303": {
              title: "투자전문그룹",
              guides: [{
                target: "회계법인, 신용평가회사, 중소기업창업투자회사",
                document: "[제출서류] 법인등기부등본(말소사항 포함)"
              }]
            },
          }
        },
        "04": {
          title: "연고자 및 자문계약전문가",
          type: "0401",
          types: {
            "0401": {
              title: "연고자",
              guides: [{
                target: "(1) 발행기업의 최대주주, 임원, 우리사주조합원, 계열회사 및 그 임원\n(2) 모집 . 매출 실적이 없는 주권비상장법인의 경우 주주\n(3) 발행인이 설립중인 회사의 경우 발기인 등",
                document: "[제출서류] 발행인 연고자라는 것을 증빙할 수 있는 서류(주주명부, 법인등기부등본, 우리사주조합 약정서 또는 한국증권금융㈜와의 지주관리위탁계약서, 우리사주조합원임을 증명할 수 있는 증빙 등)"
              }]
            },
            "0402": {
              title: "자문계약전문가",
              guides: [{
                target: "발행인에게 회계, 자문 등의 용역을 제공하는 공인회계사, 감정인, 변호사, 변리사, 세무사\n",
                document: "[제출서류] 발행인과의 자문계약서(또는 이와 유사항 계약서) 사본, 각 전문자격사 등록증, 신분증 사본"
              }]
            }
          }
        }
      }
    });

    const fileRef = ref();
    const modalParams = store.getters.modalParams(component);

    const setFile = (e) => {
      return store.getters.isAllowedExtension(e.target, "all");
    };

    const submit = () => {
      if (!fileRef.value.files.length) {
        return store.commit("setSwingMessage", "증빙 서류를 첨부해주세요.");
      }

      state.form.detailCode = state.investors[state.form.code].type;

      const args = {
        investorSeq: store.state.investor.investorSeq,
        investorSortCode: state.form.code,
        investorSortDetailCode: state.form.detailCode
      };

      if (state.form.code === "04") {
        args.investSeq = state.project.investSeq;

        if (!args.investSeq) {
          return store.commit("setSwingMessage", "관련 투자 정보를 선택해주세요.");
        }
      }

      http.put("/api/investor/auth/renewal", args).then(({data}) => {
        const formData = new FormData();
        formData.append("investorSortSeq", data.body.investorSortSeq);
        formData.append("investorSortFile", fileRef.value.files[0]);

        http.post("/api/investor/auth/renewal-file", formData).then(() => {
          store.commit("closeModal", {
            name: component.name,
            onClose(modal) {
              store.dispatch("callback", {modal});
              store.commit("setSwingMessage", "인증 신청이 완료되었습니다.");
            }
          });
        }).catch(httpError());
      });
    };

    const loadInvestProjects = (init) => {
      state.project.loaded = false;

      if (init) {
        http.get("/api/investor/projects", state.project.args).then(({data}) => {
          state.project.loaded = true;
          state.project.count = data.body.paginationInfo.totalRecordCount;
          state.project.list = data.body.list;
        });
      } else {
        state.project.args.pageIndex += 1;
        http.get("/api/investor/projects", state.project.args).then(({data}) => {
          state.project.loaded = true;
          state.project.list.push(...data.body.list);
        });
      }
    };

    return {component, state, fileRef, setFile, submit, loadInvestProjects};
  },
});
</script>

<style lang="scss" scoped>
.investor-cert {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .content {
    .part {
      padding: $px10 0 $px20 0;

      .subject {
        margin-bottom: $px8;
        font-weight: 500;
      }

      .form {
        padding-left: $px15;

        ul {
          line-height: 1.8;

          li {
            .target {
              white-space: pre-line;
            }

            .document {
              color: red;
              white-space: pre-line;
            }

            &:not(:last-child) {
              margin-bottom: $px15;
            }
          }
        }

        .file {
          border: $px1 solid #eee;
          padding: $px10;

          input {
            width: 100%;
          }
        }
      }

      &.projects {
        > ul > li {
          padding: $px20;
          border: $px1 solid #eee;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: $px15;
          }

          &:hover {
            background: $colorBackground;
          }

          &.active {
            background: $colorBackground;
            border: $px1 solid $colorPoint;
          }
        }

        .more {
          padding-top: $px20;
          text-align: center;

          .btn {
            padding: $px15 $px25;
          }
        }
      }
    }
  }

  .actions {
    padding-top: $px15;
    text-align: center;
    position: relative;

    .btn {
      padding: $px19 $px24;

      &.prev {
        margin-right: $px15;
      }

      &.next {
        width: $px210;
      }
    }
  }
}
</style>